import React, { useState, useEffect, createContext } from 'react'
import Axios from 'axios'
import Parse from 'parse'

const ApiContext = createContext()

function ApiContextProvider(props) {

    const [ settings, setSettings ] = useState({
        /* Order */
        shopifyOrderSync: false,
        d365DataAreaId: '',
        d365CustomerId: '',
        fraudAnalysisMapping: 'AllowAll',
        contactEmail:'',
        /* Inventory */
        shopifyInventorySync: false,
        newWarehouseCode: '', // local
        shopifyLocationMap: [],
        invSyncInterval: '[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]',
        d365InventoryKey: 'TotalAvailableQuantity',
        advancedModeActive: false, //local
        inventorySyncExclusionTagList:'',
        orderSyncExclusionTagList:'',
        shopifyInventorySyncTimes:{
            0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true, 9: true, 10: true, 11:true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: true, 20: true, 21: true, 22: true, 23: true
        },
    })

    const [inventoryParents, setInventoryParents] = useState([{'inital': 0}])
    const [inventoryChildren, setInventoryChildren] = useState([{'inital': 0}])

    const [ordersParents, setOrdersParents] = useState([{'inital': 0}]) // needs the empty object so Object.keys does not break before response receieved
    const [ordersChildren, setOrdersChildren] = useState([{'inital': 0}])

    let shopifySite = '';

    function fetchShopifySite(site) {
        if(site){
            shopifySite = site
        } else{
            let shopUrl = new URLSearchParams(location.search).get('shop')
            console.log(shopUrl)
            if(shopUrl){
            shopifySite = shopUrl.replace('.myshopify.com','')
            console.log(shopifySite)
            } else { 
                console.log(`ERROR: shopURL not detected`) 
                window.location.assign('/admin/login')
            }
        }
    }


    async function fetchSettings(){
        try{
            const response = await Axios.get(`/getSiteSettings?shopifySite=${shopifySite}`)
            console.log(response.data)
            return setSettings(prevState => {

                let newArr = []
                for (let i = 0; i < 23 ; i ++) {
                    if (response.data.shopifyInventorySyncTimes[i] == true) {
                        newArr.push(i)
                    }
                }
                let newInvSyncInterval = JSON.stringify(newArr)

                return {
                    ...prevState,
                    shopifyOrderSync: response.data.shopifyOrderSync,
                    d365DataAreaId: response.data.d365DataAreaId,
                    d365CustomerId: response.data.d365CustomerId,
                    contactEmail:response.data.contactEmail,
                    shopifyInventorySync: response.data.shopifyInventorySync,
                    shopifyLocationMap: response.data.shopifyLocationMap,
                    d365InventoryKey: response.data.d365InventoryKey,
                    invSyncInterval: newInvSyncInterval,
                    shopifyInventorySyncTimes: response.data.shopifyInventorySyncTimes,
                    inventorySyncExclusionTagList:response.data.inventorySyncExclusionTagList,
                    orderSyncExclusionTagList:response.data.orderSyncExclusionTagList
                }
            })
        }
        catch(err) { console.log(err) }
    }

    async function updateSettings(key, value){
        try{
            const response = await Axios.post(`/updateSiteSettings?shopifySite=${shopifySite}`,
            { key: key, value: value }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
            )
            console.log(response.data)
        }
        catch(err) { console.log(err) }
    }


    /* Inventory */

    async function inventoryFetchParents(){
        try{
            const response = await Axios.get(`/getParentInventoryJobs?shopifySite=${shopifySite}`)
            return setInventoryParents(response.data)
        }
        catch(err) { console.log(err) }
    }

    async function runInventorySync(){
        try{
            const response = await Axios.get(`/updateShopifySiteInventory?site=${shopifySite}`)
            alert('Manual inventory sync started')
            return true
        }
        catch(err) { console.log(err) }
    }

    async function reImportShopifyOrder(orderId){
        try{
            const response = await Axios.get(`/reImportShopifyOrder?site=${shopifySite}&shopifyOrderId=${orderId}`)
            return true
        }
        catch(err) { console.log(err) }
    }

    async function reImportAmazonOrder(orderId){
      try{
          const response = await Axios.get(`/reImportAmazonOrder?site=${shopifySite}&amazonOrderId=${orderId}`)
          return true
      }
      catch(err) { console.log(err) }
    }

    async function inventoryFetchChildren(parentId){
        try{
            const response = await Axios.get(`/getChildrenInventoryJobs?parentId=${parentId}`)
            return setInventoryChildren(response.data)
        }
        catch(err) { console.log(err) }
    }


    /* Orders */

    async function ordersFetchParents(amazon){
        try{
            const response = await Axios.get(`/getOrders?shopifySite=${shopifySite}&amazon=${amazon}`)
            return setOrdersParents(response.data)
        }
        catch(err) { console.log(err) }
    }

    async function ordersFetchChildren(parentId){
        try{
            const response = await Axios.get(`/getOrderItems?parentId=${parentId}`)
            return setOrdersChildren(response.data)
        }
        catch(err) { console.log(err) }
    }

    const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

    return(
        <ApiContext.Provider value={{
            fetchShopifySite, shopifySite, location, kebabize,
            settings, setSettings, fetchSettings, updateSettings,
            inventoryParents, inventoryChildren, inventoryFetchParents, inventoryFetchChildren, runInventorySync,
            ordersParents, ordersChildren, ordersFetchParents, ordersFetchChildren, reImportShopifyOrder
        }}>
            { props.children }
        </ApiContext.Provider>
    )
}

export { ApiContext, ApiContextProvider }
