import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAdminAuth } from '../context/adminAuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAdminAuthenticated } = useAdminAuth();

  if (!isAdminAuthenticated) {
    return <Navigate to="/admin/login" />;
  }

  return children;
}

export default ProtectedRoute; 