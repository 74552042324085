import React, { useState } from 'react';
import { useAdminAuth } from '../../context/adminAuthContext';
import PageTemplate from '../../components/pageTemplate';
import {
  Form,
  FormLayout,
  TextField,
  Button,
  LegacyCard,
  Banner
} from '@shopify/polaris';

export default function AdminLogin() {
  const { loginAdmin } = useAdminAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const result = await loginAdmin(username, password);
    if (!result.success) {
      setError(result.message || 'Login failed');
    }

    setLoading(false);
  };

  return (
    <PageTemplate title="Admin Login">
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <LegacyCard sectioned>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              {error && (
                <Banner status="critical">
                  {error}
                </Banner>
              )}
              
              <TextField
                label="Username"
                value={username}
                onChange={setUsername}
                autoComplete="username"
                required
              />

              <TextField
                label="Password"
                value={password}
                onChange={setPassword}
                type="password"
                autoComplete="current-password"
                required
              />

              <Button submit primary loading={loading}>
                Login
              </Button>
            </FormLayout>
          </Form>
        </LegacyCard>
      </div>
    </PageTemplate>
  );
} 