import React, { Component } from "react";
import './styles/app.scss';
import Settings from "./pages/settings/";
import Inventory from "./pages/inventory";
import InventoryDetailed from "./pages/inventoryDetailed";
import Orders from "./pages/orders";
import AmazonOrders from "./pages/orders/amazon";
import OrdersDetailed from "./pages/ordersDetailed";
import AdminOrders from "./pages/admin/orders";
import AdminLogin from "./pages/admin/login";
import ProtectedRoute from "./components/ProtectedRoute";
import { AdminAuthProvider } from "./context/adminAuthContext";

import {
  Routes,
  Route,
  Link
} from "react-router-dom";

class App extends Component {
  constructor(props) {
      super(props);
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <div>
        <AdminAuthProvider>
          <div>
            <Routes>
              <Route exact path="/inventory" element={<Inventory />}/>
              <Route path='/inventory/:inventoryId' element={<InventoryDetailed />}/>

              <Route exact path="/orders" element={<Orders />}/>
              <Route exact path="/amazonOrders" element={<AmazonOrders />}/>
              <Route path='/orders/:orderId' element={<OrdersDetailed />}/>
              
              {/* Admin Routes */}
              <Route exact path="/admin/login" element={<AdminLogin />}/>
              <Route exact path="/admin/orders" element={
                <ProtectedRoute>
                  <AdminOrders />
                </ProtectedRoute>
              }/>

              <Route path="/settings" element={<Settings />}/>
              <Route exact path="/" element={<Settings />}/>
            </Routes>
          </div>
        </AdminAuthProvider>
      </div>
    );
  }
}

export default App;
